import * as React from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { BoostIcon } from "./BoostIcon";

export default function SearchResultList(props) {
  function send_to_maps() {
    window.open("https://maps.google.com/?q=" + props.address);
  }
  return (
    <List
      onClick={send_to_maps}
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton>
        <ListItemIcon>
          <BoostIcon />
        </ListItemIcon>
        <ListItemText
          primary={props.cmp_name.toUpperCase()}
          secondary={props.address}
        />
      </ListItemButton>
    </List>
  );
}
