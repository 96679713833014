import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import NativeSelect from "@mui/material/NativeSelect";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import SearchResultCard from "../Client/SearchResult";
import { useState, useEffect } from "react";
import { queryStore } from "../Admin/Upload";

export default function Search() {
  const [search_value, set_search_value] = useState("");
  const [option_value, set_option_value] = useState(10);
  const [search_results, set_search_result] = useState([]);

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log("Use Effect Called ");
    console.log("Option Value = " + option_value);
  });
  // Search Store base on User Input
  async function searchStore() {
    // Make sure Input value is not empty
    if (search_value.trim().length === 0)
      console.log("Please enter Value for Search Store");
    else if (option_value === 0) console.log("Please Select a search Option ");
    else {
      console.log("Here");
      console.log("went through");
      set_search_result(await queryStore(search_value, option_value));
    }
  }
  //<a href="https://maps.google.com/?q=addressgoeshere">Helll</a>
  return (
    <Grid
      style={{ marginTop: "100px" }}
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid xs={12}>
        <Typography variant="button" display="block" gutterBottom>
          Search By street address, city or zipcode
        </Typography>
      </Grid>
      <Grid container>
        <Grid xs={8}>
          <TextField
            id="standard-search"
            label="Search Store"
            type="search"
            variant="standard"
            value={search_value}
            onChange={(e) => set_search_value(e.target.value)}
          />
        </Grid>
        <Grid xs={4}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="uncontrolled-native">
              Using
            </InputLabel>
            <NativeSelect
              onChange={(e) => {
                set_option_value(e.target.value);
              }}
              inputProps={{
                name: "search_option",
                id: "uncontrolled-native",
              }}
            >
              <option value={10}>Street #</option>
              <option value={20}>Company Name</option>
              <option value={30}>City</option>
              <option value={40}>ZipCode</option>
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "20px" }}
        >
          <Button
            variant="outlined"
            size="medium"
            onClick={() => {
              searchStore();
            }}
          >
            Search
          </Button>
        </Grid>
      </Grid>
      <Grid>
        <Grid xs={12} style={{ marginTop: "50px" }}>
          {search_results.map((item, key) => (
            <SearchResultCard
              key={key}
              cmp_name={item.CompanyName}
              address={
                item.StreetInfo +
                ", " +
                item.City +
                ", " +
                item.State +
                " " +
                item.ZipCode
              }
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}
