import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyAZ8frSHQHHwDb7fvNlnPpfF1SMer3Ne_k",
  authDomain: "booststores-492cb.firebaseapp.com",
  databaseURL: "https://booststores-492cb-default-rtdb.firebaseio.com",
  projectId: "booststores-492cb",
  storageBucket: "booststores-492cb.appspot.com",
  messagingSenderId: "862692078489",
  appId: "1:862692078489:web:7083fffc77021fba505908",
  measurementId: "G-TVQVJ7J0SP",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const db = getFirestore(app);
