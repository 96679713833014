/* eslint-disable */
import { db } from "../../../firebase";
import { collection, addDoc, getDocs, query, where } from "firebase/firestore";
export function Startfirebase() {}

// Add data to the database
export async function saveStore(storeInfo) {
  storeInfo = storeInfo.split("|");
  const address = storeInfo[1].split(",");
  console.log(address);
  try {
    const docRef = await addDoc(collection(db, "Stores"), {
      CompanyName: storeInfo[0].trim().toLowerCase(),
      StreetInfo: address[0].trim().toLowerCase(),
      City: address[1].trim().toLowerCase(),
      State: address[2].trim().split(" ")[0].toLowerCase(),
      ZipCode: address[2].trim().split(" ")[1].toLowerCase(),
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
}

// Fucntion Used to search in the database
export async function queryStore(query_s, params) {
  const temp_search_value = [];

  // Get appropriate query Parameter
  var search_option = await filter_option(params);
  const search_query = query(
    collection(db, "Stores"),
    where(String(search_option), "==", String(query_s.toLowerCase()))
  );

  const query_result = await getDocs(search_query);
  query_result.forEach((doc) => {
    console.log(doc.data());
    temp_search_value.push(doc.data());
  });
  return temp_search_value;
}

async function filter_option(params) {
  // Filter Search Option for Query
  console.log(params);
  var search_option = 10;
  switch (params) {
    case "10":
      search_option = "StreetInfo";
      return search_option;
      break;
    case "20":
      search_option = "CompanyName";
      return search_option;
      break;
    case "30":
      search_option = "City";
      return search_option;
      break;
    case "40":
      search_option = "ZipCode";
      return search_option;
      break;
    default:
      search_option = "StreetInfo";
      return search_option;
  }
}
