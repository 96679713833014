import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useState, useEffect } from "react";
import { saveStore } from "../Admin/Upload";

export default function AdminDashboard() {
  const [rawdata, setrawdata] = useState();
  useEffect(() => {
    console.log("[UseEffect]Called");
  });

  const handleUploadClick = async (e) => {
    // Get the data from State
    const formatted_result = rawdata.split(";");
    for (let i = 0; i < formatted_result.length; i++) {
      await saveStore(formatted_result[i]);
    }

    // Save to DB
  };

  return (
    <Grid
      xs={12}
      style={{ marginTop: "100px" }}
      container
      spacing={2}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Grid xs={12}>
        <Typography variant="button" display="block" gutterBottom>
          Upload Data
        </Typography>
      </Grid>
      <Grid container>
        <Grid xs={8}>
          <TextField
            style={{ width: "400px" }}
            id="filled-multiline-flexible"
            label="Uplaod Raw data to Database"
            multiline
            onChange={(e) => setrawdata(e.target.value)}
            value={rawdata}
            maxRows={10}
            variant="filled"
          />
        </Grid>
        <Grid xs={4}>
          <Button
            onClick={() => handleUploadClick()}
            variant="outlined"
            size="medium"
          >
            Upload
          </Button>
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: "20px" }}
        ></Grid>
      </Grid>
    </Grid>
  );
}
