import "./App.css";
import CAppBar from "./AppBar";
import Search from "./Client/Search";
import AdminDashboard from "./Admin/AdminDashboard";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <CAppBar></CAppBar>
      <Routes>
        <Route path="/" element={<Search />} />
        <Route path="/admdashboard" element={<AdminDashboard />} />
      </Routes>
    </div>
  );
}

export default App;
